/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Newsletter from 'app/layouts/newsletter';

export default function CopyrightFooter() {
  const navigate = useNavigate();
  return (
    <Fragment>
      {/* <Newsletter /> */}
      <Grid container style={{ position: 'relative', paddingTop: '48px' }}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              style={{ paddingBottom: '0px' }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  align="center"
                  color="primary"
                  fontWeight="fontWeightBold"
                  paragraph
                  style={{
                    minWidth: '100px',
                    paddingTop: '48px',
                    // paddingBottom: '12px',
                    marginBottom: '0px',
                    fontWeight: '600',
                  }}
                >
                  Product
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="left"
                  color="textSecondary"
                  style={{ padding: '16px 15% 0px 15%' }}
                >
                  <Link
                    color="textSecondary"
                    to="/product"
                    href="/product"
                    onClick={() => {
                      navigate('/product');
                    }}
                    variant="body2"
                    style={{
                      cursor: 'pointer',
                      padding: '0px 8px 0px 8px',
                      textDecoration: 'none',
                    }}
                  >
                    Product Overview
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="left"
                  color="textSecondary"
                  style={{ padding: '16px 15% 0px 15%' }}
                >
                  <Link
                    to="/product/leadsearch-sales-intelligence"
                    href="/product/leadsearch-sales-intelligence"
                    color="textSecondary"
                    onClick={() => {
                      navigate('/product/leadsearch-sales-intelligence');
                    }}
                    variant="body2"
                    style={{
                      cursor: 'pointer',
                      padding: '0px 8px 0px 8px',
                      textDecoration: 'none',
                    }}
                  >
                    Sales Intelligence
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="left"
                  color="textSecondary"
                  style={{ padding: '16px 15% 0px 15%' }}
                >
                  <Link
                    color="textSecondary"
                    to="/product/ai-analysis-and-crm"
                    href="/product/ai-analysis-and-crm"
                    onClick={() => {
                      navigate('/product/ai-analysis-and-crm');
                    }}
                    variant="body2"
                    style={{
                      cursor: 'pointer',
                      padding: '0px 8px 0px 8px',
                      textDecoration: 'none',
                    }}
                  >
                    AI Analysis and CRM
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="left"
                  color="textSecondary"
                  style={{ padding: '16px 15% 0px 15%' }}
                >
                  <Link
                    color="textSecondary"
                    to="/product/sales-automation"
                    href="/product/sales-automation"
                    onClick={() => {
                      navigate('/product/sales-automation');
                    }}
                    variant="body2"
                    style={{
                      cursor: 'pointer',
                      padding: '0px 8px 0px 8px',
                      textDecoration: 'none',
                    }}
                  >
                    Sales Automation
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="left"
                  color="textSecondary"
                  style={{ padding: '16px 15% 0px 15%' }}
                >
                  <Link
                    color="textSecondary"
                    to="/product/connect-email"
                    href="/product/connect-email"
                    onClick={() => {
                      navigate('/product/connect-email');
                    }}
                    variant="body2"
                    style={{
                      cursor: 'pointer',
                      padding: '0px 8px 0px 8px',
                      textDecoration: 'none',
                    }}
                  >
                    Connect Email
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="left"
                  color="textSecondary"
                  style={{ padding: '16px 15% 0px 15%' }}
                >
                  <Link
                    color="textSecondary"
                    to="/our-data"
                    href="/our-data"
                    onClick={() => {
                      navigate('/our-data');
                    }}
                    variant="body2"
                    style={{
                      cursor: 'pointer',
                      padding: '0px 8px 0px 8px',
                      textDecoration: 'none',
                    }}
                  >
                    Our Data
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              style={{ paddingBottom: '0px' }}
            >
              <Typography
                variant="h5"
                align="center"
                color="primary"
                fontWeight="fontWeightBold"
                paragraph
                style={{
                  minWidth: '100px',
                  paddingTop: '48px',
                  // paddingBottom: '12px',
                  marginBottom: '0px',
                  fontWeight: '600',
                }}
              >
                Solutions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/solutions"
                  href="/solutions"
                  onClick={() => {
                    navigate('/solutions');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Solutions Overview
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/solutions/sales"
                  href="/solutions/sales"
                  onClick={() => {
                    navigate('/solutions/sales');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Sales Solutions
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/solutions/marketing"
                  href="/solutions/marketing"
                  onClick={() => {
                    navigate('/solutions/marketing');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Marketing Solutions
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/solutions/founders"
                  href="/solutions/founders"
                  onClick={() => {
                    navigate('/solutions/founders');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Founder Solutions
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              style={{ paddingBottom: '0px' }}
            >
              <Typography
                variant="h5"
                align="center"
                color="primary"
                fontWeight="fontWeightBold"
                paragraph
                style={{
                  minWidth: '100px',
                  paddingTop: '48px',
                  // paddingBottom: '12px',
                  marginBottom: '0px',
                  fontWeight: '600',
                }}
              >
                Resources
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/our-story"
                  href="/our-story"
                  onClick={() => {
                    navigate('/our-story');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Our story
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/insights"
                  href="/insights"
                  onClick={() => {
                    navigate('/insights');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Insights
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/support"
                  href="/support"
                  onClick={() => {
                    navigate('/support');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Support
                </Link>
              </Typography>
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              style={{ paddingBottom: '0px' }}
            >
              <Typography
                variant="h5"
                align="center"
                color="primary"
                fontWeight="fontWeightBold"
                paragraph
                style={{
                  minWidth: '100px',
                  paddingTop: '48px',
                  // paddingBottom: '12px',
                  marginBottom: '0px',
                  fontWeight: '600',
                }}
              >
                Pricing
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/start-free"
                  href="/start-free"
                  onClick={() => {
                    navigate('/start-free');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Start free
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/pricing/?d=Monthly"
                  href="/pricing/?d=Monthly"
                  onClick={() => {
                    navigate('/pricing/?d=Monthly');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Monthly Pricing
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                style={{ padding: '16px 15% 0px 15%' }}
              >
                <Link
                  color="textSecondary"
                  to="/pricing/?d=Annually"
                  href="/pricing/?d=Annually"
                  onClick={() => {
                    navigate('/pricing/?d=Annually');
                  }}
                  variant="body2"
                  style={{
                    cursor: 'pointer',
                    padding: '0px 8px 0px 8px',
                    textDecoration: 'none',
                  }}
                >
                  Annual Pricing
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '36px' }}>
          <Typography
            variant="body1"
            align="center"
            color="textSecondary"
            style={{ padding: '16px 0px 0px 0px' }}
          >
            <Link
              color="textSecondary"
              to="/terms"
              href="/terms"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open('/terms');
                }
              }}
              variant="body1"
              style={{ cursor: 'pointer', padding: '0px 8px 0px 8px' }}
            >
              Terms
            </Link>
            |
            <Link
              color="textSecondary"
              to="/privacy"
              href="/privacy"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open('/privacy');
                }
              }}
              variant="body1"
              style={{ cursor: 'pointer', padding: '0px 8px 0px 8px' }}
            >
              Privacy
            </Link>
            |
            <Link
              color="textSecondary"
              to="/cookies"
              href="/cookies"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open('/cookies');
                }
              }}
              variant="body1"
              style={{ cursor: 'pointer', padding: '0px 8px 0px 8px' }}
            >
              Cookies
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            color="textSecondary"
            align="center"
            style={{ padding: '48px 20% 22px' }}
          >
            {'Copyright © '}
            <Link
              to="/"
              href="/"
              onClick={() => {
                navigate({
                  pathname: '/',
                });
              }}
              variant="body1"
              className="pagelink"
            >
              Find Me Sales
            </Link>{' '}
            Ltd {new Date().getFullYear()}. Find Me Sales is a limited company
            incorporated in England, company registration number 13664185,
            registered address: 86-90 Paul Street, London, England, United
            Kingdom, EC2A 4NE.
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
}
